export const GET_EXERCISES_SUCCESS = 'Get exercises success';
export const GET_EXERCISES_REQUEST = 'Get exercises request';
export const GET_USER_EXERCISES_REQUEST = 'Get user exercises request';
export const GET_USER_EXERCISES_SUCCESS = 'Get user exercises success';
export const EXERCISE_STEP_IS_VALID = 'Exercise step is valid';
export const EXERCISE_STEP_IS_INVALID = 'Exercise Step is invalid';
export const EXERCISE_RESOLVED = 'Exercise is resolved';
export const EXPRESSION_CHANGE_SUCCESSFULLY = 'Expression change sucessfully';
export const RESOLVE_EXERCISE_REQUEST = 'Resolve exercise request';
export const CREATE_EXERCISE_SUCCESS = 'Create exercise success';
export const GENERATE_PLAYGROUND_EXERCISE_SUCCESS = 'Generate playground exercise success';
export const FETCH_PLAYGROUND_EXERCISE_SUCCESS = 'Get playground exercise success';
export const FETCH_PLAYGROUND_EXERCISE_LIST_SUCCESS = 'Get playground exercise list success';

export const CREATE_EXERCISE_REQUEST = 'Create exercise request';
export const CREATE_EXERCISE_FAIL = 'Create exercise fail';
export const EVALUATE_EXERCISE_SUCCESS = 'Evaluate exercise success';
export const EVALUATE_EXERCISE_REQUEST = 'Evaluate exercise request';
export const EVALUATE_EXERCISE_FAIL = 'Evaluate exercise fail';
export const GET_EXERCISE_SUCCESS = 'Get exercise success';
export const GET_ALL_RESOLUTIONS_SUCCESS = 'Get other resolutions success';
export const GET_USER_EXERCISE_SUCCESS = 'Get user exercise success';
export const UPDATE_EXERCISE = 'Update exercise';
export const UPDATE_PIPELINE_STATUS = 'Update pipeline status';
export const UPDATE_STUDENT_EXERCISE_SUCCESS = 'Update student exercise success';
export const REMOVE_EXERCISE_STEP = 'Remove exercise step';
export const REMOVE_EXERCISE_DETAIL = 'Remove exercise detail';
export const RESET_EXERCISE_ERROR = 'Reset exercise error';
export const RESET_SOLVED_EXERCISE = 'Reset solved exercise';
export const DELETE_EXERCISE_SUCCESS = 'Delete exercise success';
export const DELETE_EXERCISE_REQUEST = 'Delete exercise request';
export const DELIVER_EXERCISE_REQUEST = 'Deliver exercise request';
